import merge from '~lodash.mergewith'
import * as CoreSdk from '~@sentry/core'
import { captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } from '~@sentry/browser'
import { extraErrorDataIntegration } from '~@sentry/integrations'
import { init } from '~@sentry/vue'

export { init }
export const SentrySdk = { ...CoreSdk, ...{ captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } }

/** @type {string[]} */
const DISABLED_INTEGRATION_KEYS = ["ReportingObserver","GlobalHandlers"]

/**
 * @typedef {Parameters<typeof init>[0]} InitConfig
 * @param {import('@nuxt/types').Context} ctx
 * @return {Promise<InitConfig>}
 */
export function getConfig (ctx) {
  /** @type {InitConfig} */
  const config = {
    dsn:"https:\u002F\u002Fac661c2055f546d9986ecd5f933479c7@sentry.aws.delfi.net\u002F4",
    environment:"production",
    normalizeDepth:5,
    release:"1.9.59",
    sampleRate:0.5,
    tracesSampleRate:0.7,
    beforeSend:(event, hint) => {
        if (event.contexts?.service?.responseCode === 404) {
          return null;
        }

        const originalException = hint.originalException;
        const errorMessage = originalException?.message || event.message;

        if (errorMessage) {
          console.log(errorMessage);
        }

        return event;
      },
    beforeBreadcrumb:function(breadcrumb) {
        if (breadcrumb.category === 'console' || breadcrumb.category === 'xhr') {
          return null;
        }

        if (process.client && breadcrumb.category === 'Page') {
          console.info(`Page: ${breadcrumb.message}`);
        }

        return breadcrumb;
      },
    allowUrls:[new RegExp("https?:\\\u002F\\\u002F([a-z0-9]*\\.)?(?:delfi|nh)\\.(?:ee|net|lv|lt)", "")],
    denyUrls:[new RegExp("g\\.delfi\\.ee\\\u002Fscms", ""),new RegExp("g\\.delfi\\.ee\\\u002Fmisc\\\u002Frembi", ""),new RegExp("extensions\\\u002F", "i"),new RegExp("^chrome:\\\u002F\\\u002F", "i")],
    ignoreErrors:["originalCreateNotification","canvas.contentDocument","bmi_SafeAddOnload","EBCallBackMessageReceived","VotingBannedError","RegisteredUserEmailNotVerifiedError","AbortError","NotAllowedError","Blocked a frame with origin","Network Error"],
  }

  /** @type {NonNullable<InitConfig>['integrations']} */
  const resolvedIntegrations = [
    extraErrorDataIntegration(),
  ]

  config.integrations = (defaultIntegrations) => {
    return [
      ...defaultIntegrations.filter(integration => !DISABLED_INTEGRATION_KEYS.includes(integration.name)),
      ...resolvedIntegrations,
    ]
  }
  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
