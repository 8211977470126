var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content-related-articles-single"},[_c('LinkHandler',{staticClass:"content-embedded-articles-single__embedded-article",attrs:{"href":_vm.buildArticleRoute(
        _vm.embeddedArticles.items[0].id,
        _vm.embeddedArticles.items[0].slug,
        {
          language: _vm.embeddedArticles.items[0].categories.items[0].channel.language,
          domain: _vm.embeddedArticles.items[0].categories.items[0].channel.url,
        },
        _vm.domain
      )}},[_c('div',{staticClass:"content-embedded-articles-single__image",style:({ 'background-image': `url(${_vm.buildPictureUrl({ id: _vm.embeddedArticles.items[0].metaImage.id, cropperData: { width: 500 } })})` })}),_vm._v(" "),_c('div',{staticClass:"content-embedded-articles-single__info"},[_c('div',{staticClass:"content-embedded-articles-single__published-at"},[_vm._v("\n        "+_vm._s(_vm.formatCustomDate(_vm.embeddedArticles.items[0].publishAt, 'DD.MM.YYYY'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"content-embedded-articles-single__title"},[_c('span',{staticClass:"content-embedded-articles-single__title-text"},[_vm._v("\n          "+_vm._s(_vm.embeddedArticles.items[0].content.title.text)+"\n        ")]),_vm._v(" "),(_vm.embeddedArticles.items[0].statistics.comments)?_c('span',{staticClass:"content-embedded-articles-single__comments-count"},[_vm._v("\n          "+_vm._s(`(${_vm.embeddedArticles.items[0].statistics.comments})`)+"\n        ")]):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }