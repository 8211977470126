
import Vue from 'vue';
import { FragmentAttrsRelatedArticles } from '@core/types/fragment';
import { formatCustomDate } from '@core/exports';
import { EmbeddedArticlesElement } from '@root/modules/article/types/getArticleByID';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import buildPictureUrl from '@core/utils/buildPictureUrl';
import { buildArticleRoute } from '@core/utils/helpers';

interface Props {
  attributes: FragmentAttrsRelatedArticles;
  domain: string;
  embeddedArticles: EmbeddedArticlesElement[];
}

interface Methods {
  buildArticleRoute: typeof buildArticleRoute;
  buildPictureUrl: typeof buildPictureUrl;
  formatCustomDate: typeof formatCustomDate;
}

export default Vue.extend<unknown, Methods, unknown, Props>({
  components: { LinkHandler },
  props: {
    attributes: {
      type: Object,
      required: true,
    },
    domain: {
      type: String,
      required: true,
    },
    embeddedArticles: {
      type: Object,
      required: true,
    },
  },
  methods: {
    buildArticleRoute,
    buildPictureUrl,
    formatCustomDate,
  },
});
